import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/ThemeSwitch.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/WelcomePopover.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/css/tailwind.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/css/april-fools.css");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/vercel/path0/node_modules/next-themes/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/vercel/path0/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Space_Grotesk\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-space-grotesk\"}],\"variableName\":\"space_grotesk\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/pliny/chunk-MBYDL2NI.js");
;
import(/* webpackMode: "eager", webpackExports: ["AlgoliaSearchProvider"] */ "/vercel/path0/node_modules/pliny/chunk-O6XQYU4T.js");
;
import(/* webpackMode: "eager", webpackExports: ["KBarSearchProvider"] */ "/vercel/path0/node_modules/pliny/chunk-XWEUNOHX.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/pliny/search/algolia.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/remark-github-blockquote-alert/alert.css");
